import React from "react";

import { Helmet } from "react-helmet";
import useSiteMetadata from "./SiteMetadata";
import { useLocation } from "@reach/router";

const MetaTags = ({ children, title: titleProp, description, imageUrl }) => {
  const { pathname } = useLocation();
  let siteMeta = useSiteMetadata();
  let siteTitle = siteMeta.title;

  const { siteUrl } = siteMeta;
  let url = `${siteUrl}${pathname}`;
  let title = siteTitle;

  if (titleProp) {
    title = `${titleProp} | ${siteTitle}`;
  } else if (children) {
    title = `${children} | ${siteTitle}`;
  }

  let titleTags;
  if (title) {
    titleTags = [
      <title key="plain">{title}</title>,
      <meta key="meta" name="title" content={title} />,
      <meta key="og" property="og:title" content={title} />,
      <meta key="tw" property="twitter:title" content={title} />,
    ];
  }

  let urlTags;
  if (url) {
    urlTags = [
      <meta key="og" property="og:url" content={url} />,
      <meta key="tw" property="twitter:url" content={url} />,
    ];
  }

  let descriptionTags;
  if (description) {
    descriptionTags = [
      <meta key="meta" name="description" content={description} />,
      <meta key="og" property="og:description" content={description} />,
      <meta key="tw" property="twitter:description" content={description} />,
    ];
  }

  let imageTags;

  if (imageUrl) {
    imageTags = [
      <meta key="og" property="og:image" content={imageUrl} />,
      <meta key="tw" property="twitter:image" content={imageUrl} />,
    ];
  }

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />
      {titleTags}
      {urlTags}
      {descriptionTags}
      {imageTags}
    </Helmet>
  );
};

export default MetaTags;
