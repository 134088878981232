import React from "react";
import { StyledLink, PageWrapper, Button, rem } from "../styles";
import ty from "../styled-tachyons";
import styled from "styled-components";
import { useWindowSize } from "../hooks/useWindowSize";

export const NavItem = styled(StyledLink)`
  ${ty`tc tr-l tl-l f4 f5-l db dib-l pv1 mr0 ttu fw6`}
`;

const NavLogo = styled(NavItem)`
  ${ty`mr0-l ttn flex-l items-center`};
  flex-shrink: 0;
  .short-logo {
    display: none;
  }
`;
const ContactButton = styled(Button)`
  ${ty`bg-transparent white b--red`}
`;

const NavHamburger = styled.div`
  ${ty`dn-l pa0-l link dim white pointer`}
  span:first-child {
    ${ty`db w2 mt1 mb2 pb2 bt bb b--white bw2`}
  }

  span:last-child {
    ${ty`db w2 mt1 pb1 bt b--white bw2`}
  }

  ${(p) => {
    if (p.active) {
      return `
        transform: rotate(45deg);
        span:first-child {
          border-top-width: 0;
        }
        span:last-child {
          transform:translateX(-5%) translateY(-175%) rotate(90deg);
        }
      `;
    }
  }}
`;
const NavMenu = styled.nav`
  ${ty`w-100 pv3 pa4-l ph3 ph4-l bg-near-black white`}
  ${PageWrapper} {
    ${ty`flex justify-between`}
  }

  ${NavItem}:not(${NavLogo}) {
    ${ty`ttu`}
  }

  ${NavLogo}, ${NavHamburger} {
    ${ty`z-3`}
  }

  ${NavItem}:not([href='/'])[aria-current] {
    ${ty`underline`}
  }

  @media screen and (min-width: ${rem(960)}) and (max-width: ${rem(1119)}) {
    ${NavLogo} {
      .long-logo {
        display: none;
      }
      .short-logo {
        display: block;
      }
    }
    ${NavItem}.contact-wrapper {
      margin-right: 0;
    }
  }
`;

const NavList = styled.div`
  height: 0;
  ${ty`static-l absolute left-0 db overflow-hidden bg-near-black  v-mid w-100 z-2 tr items-center`}
  ${ty`flex flex-column justify-start`}
  ${ty`h-auto-l flex flex-row-l justify-end-l  pt0-l`}
  transition: height 0.25s ease-in-out, padding 0.25s ease-in-out;
  ${ty`${(p) => {
    if (p.active) {
      return "h-100 pt5 overflow-auto";
    } else {
      // return "dn";
    }
  }}`}


  ${(p) => {
    if (p.active) {
      return `
          ${NavItem} {
            margin-bottom: 2rem;
          }

          & {
            height: calc(100vh - var(--spacing-medium));
          }
        `;
    }
  }}
  .contact-wrapper {
    order: 999;
  }
  ${NavItem} {
    ${ty`mr4-l`}
    transition: margin 0.25s ease-in-out;
  }
`;

const Navbar = ({ onActiveChange }) => {
  const [active, setActive] = React.useState(false);

  const toggleHamburger = React.useCallback(() => {
    setActive((c) => !c);
    if (onActiveChange) {
      onActiveChange(!active);
    }
  }, [active, onActiveChange]);

  let windowSize = useWindowSize();
  React.useEffect(() => {
    if (windowSize?.width > 959 && active) {
      toggleHamburger();
    }
  }, [windowSize, active, toggleHamburger]);

  return (
    <>
      <NavMenu active={active}>
        <PageWrapper full>
          <NavLogo to="/intro">
            <div className="long-logo" css={ty`red b f3`}>
              Art Reality Studio
            </div>
            <div className="short-logo" css={ty`red b f3`}>
              ARS
            </div>
          </NavLogo>
          <NavList active={active}>
            <NavItems />
          </NavList>
          <NavHamburger onClick={toggleHamburger} active={active}>
            <span></span>
            <span></span>
          </NavHamburger>
        </PageWrapper>
      </NavMenu>
    </>
  );
};

export const NavItems = () => {
  return (
    <>
      <NavItem as="div" className="contact-wrapper">
        <ContactButton to="/get-involved">Get Involved</ContactButton>
      </NavItem>
      <NavItem to="/">Home</NavItem>
      <NavItem to="/artists">Artists</NavItem>
      <NavItem to="/events">Events</NavItem>
      <NavItem to="/programs">Programs</NavItem>
      <NavItem to="https://www.arsnet.io/">ARS Net</NavItem>
      <NavItem to="/contact">Contact Us</NavItem>
    </>
  );
};

export default Navbar;
