import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import ty from "../styled-tachyons";
import { navigate } from "gatsby-link";

import Layout from "../components/Layout";
import {
  DarkGraySection,
  PageHeader,
  Button,
  BodyText,
  SectionHeader,
} from "../styles";
import MetaTags from "../components/MetaTags";

export function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}
const inputStyles = css`
  ${ty`b--red ba br4 bg-transparent pv2 mb3`}
  &,&::placeholder {
    ${ty`tc white`}
  }

  &:focus {
    outline: none;
    ${ty`b--white`}
    &::placeholder {
      ${ty`tc white near-black`}
    }
  }
`;

const NameInput = styled.input`
  ${inputStyles}
`;
const EmailInput = styled.input.attrs({
  type: "email",
})`
  ${inputStyles}
`;

const MessageInput = styled.textarea`
  ${inputStyles}
  ${ty`h4 mb3`}
  &::placeholder {
    line-height: 7rem;
  }
`;

const ContactForm = styled(({ className }) => {
  const onSubmit = React.useCallback(async (e) => {
    e.preventDefault();
    const form = e.target;
    const values = {
      name: form.elements.name.value,
      email: form.elements.email.value,
      message: form.elements.message.value,
      "bot-field": form.elements["bot-field"].value,
    };
    // console.log({ values });
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...values,
        }),
      });
      navigate(form.getAttribute("action"));
    } catch (e) {
      alert(e);
    }
  }, []);
  return (
    <form
      className={className}
      onSubmit={onSubmit}
      name="contact"
      method="post"
      action="/contact/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out: <input name="bot-field" id="bot-field" />
        </label>
      </div>
      <NameInput placeholder="Name*" id="name" name="name" required />
      <EmailInput placeholder="Email*" id="email" name="email" required />
      <MessageInput
        placeholder="Message*"
        id="message"
        name="message"
        required
      />
      <div css={ty`center mb3 f7`}>* required field</div>
      <Button as="button" ty="self-center ph5" type="submit">
        Submit
      </Button>
    </form>
  );
})`
  ${ty`flex flex-column mw6 center ph3`}
`;

const ContactPageStyle = styled(DarkGraySection)`
  ${SectionHeader} {
    ${ty`mb2 mt5 f3 tracked fw7`}
  }
  ${ContactForm} {
    ${ty`pt1`}
  }
`;

const ThankYouSection = styled(BodyText)`
  ${inputStyles}

  ${ty`mw6 f3 pv5 mt5 fw7`}
`;

export const ContactPageTemplate = ({ title, submitted }) => {
  let content;

  if (submitted) {
    content = (
      <ThankYouSection fontSize="large">
        Thank You! <br />
        We have received your message to Art Reality Studio.
      </ThankYouSection>
    );
  } else {
    content = (
      <>
        <SectionHeader>Contact Us</SectionHeader>
        <ContactForm />
      </>
    );
  }

  return (
    <>
      <MetaTags title={title} />
      <ContactPageStyle>
        <PageHeader
          css={`
            ${ty`tc`}
          `}
        >
          Join the ARS Community
        </PageHeader>
        <BodyText>
          Please get in touch if you have questions or ideas for ARS.
          <br /> We are building a community of engaged visionaries who believe
          art is essential to understanding the world we inhabit.
        </BodyText>
        {content}
      </ContactPageStyle>
    </>
  );
};

ContactPageTemplate.propTypes = {};

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  // console.log(data);

  return (
    <Layout>
      <ContactPageTemplate {...frontmatter} />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
      }
    }
  }
`;
