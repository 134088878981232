import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix, navigate } from "gatsby";

import styled, { createGlobalStyle } from "styled-components";
import ty, { tachyonsVariables } from "../styled-tachyons";
import MetaTags from "./MetaTags";

const GlobalStyle = createGlobalStyle`
${tachyonsVariables};
:root {
  --red: #FF4A4A;
  --washed-red: #FF6666;
  --near-black: #1D1D1D;
  --dark-gray: #414040;

  --sans-serif: prompt, sans-serif;
}
html {
  font-size: 16px;
}
body {
  font-family: prompt, sans-serif;
}

html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
}

body {
    margin: 0px;
}
h1,h2,h3,h4,h5,h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

a:visited, a { 
  &:not(.no-link-style) {
    color: var(--washed-red);
  }
}
a:hover {
  &:not(.no-link-style) {
    color: gray;
  }
}

`;

const Content = styled.div``;

export const PageContainer = styled.div`
  height: 100%;

  ${ty`flex flex-column`}
  ${Content} {
    flex: 1 0 auto;
    ${ty`flex flex-column`}
    & > * {
      flex-grow: 1;
    }
  }

  ${(p) => {
    if (p.mobileNavActive) {
      return {
        overflow: "hidden",
      };
    }
  }}
`;

const testBot = () => {
  var botPattern =
    "(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
  var re = new RegExp(botPattern, "i");
  var userAgent = navigator.userAgent;
  if (re.test(userAgent)) {
    return true;
  }
  return false;
};
export const seenIntroKey = "seen-intro";
let seenIntro = false;
export const Layout = ({ title, description, children, bare, preview }) => {
  // let seenIntro = false;
  let [isBot, setIsBot] = React.useState(true);
  React.useEffect(() => {
    if (!preview) {
      setIsBot(testBot());
    }
  }, [preview]);
  let [mobileNavActive, setMobileNavActive] = React.useState();
  let onActiveChange = React.useCallback((isActive) => {
    console.log({ isActive });
    setMobileNavActive(isActive);
  }, []);
  try {
    seenIntro = seenIntro || window.location.pathname.includes("intro");
    // if (!window.location.pathname.includes("intro")) {
    // seenIntro = !!localStorage.getItem(seenIntroKey);
    if (!window.location.search.includes("p=")) {
      if (!isBot && !seenIntro) {
        console.log("should redirect");
        const goBackTo = window.location.pathname;
        let query = "";
        if (goBackTo !== "/" && !goBackTo.includes("/intro")) {
          query = `?to=${encodeURIComponent(goBackTo)}`;
        }
        navigate(`/intro/${query}`);
        seenIntro = true;
        return null;
      }
    }
    // }
  } catch (e) {}

  let content = <Content>{children}</Content>;
  if (!bare) {
    content = (
      <>
        <Navbar onActiveChange={onActiveChange} />
        {content}
        <Footer />
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
      </Helmet>
      {!preview ? (
        <MetaTags
          title={title}
          description={description}
          imageUrl={`${withPrefix("/")}img/og-image.jpg`}
        />
      ) : null}
      <PageContainer mobileNavActive={mobileNavActive}>{content}</PageContainer>
    </>
  );
};

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <Layout title={title} description={description}>
      {children}
    </Layout>
  );
};

export default TemplateWrapper;
