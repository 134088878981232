import React from "react";
import classnames from "classnames";
import ty from "../styled-tachyons";
import Img from "gatsby-image";
import styled, { css } from "styled-components";
import _ from "lodash";
import tachyonsJS from "tachyons-js";

import { Link } from "gatsby";
import arrowRightBlack from "../img/buttons/arrow-right-black.svg";
import arrowRightWhite from "../img/buttons/arrow-right-white.svg";
import arrowRightRed from "../img/buttons/arrow-right-red.svg";

export const ns = (rules) => {
  return `@media screen and (min-width: 30em) {
    ${rules}
  }`;
};

export const m = (rules) => {
  return `@media screen and (min-width: 30em) and (max-width: 60em) {
    ${rules}
  }`;
};

export const l = (rules) => {
  return `@media screen and (min-width: 60em) {
    ${rules}
  }`;
};

export const nsQuery = Object.keys(tachyonsJS.absolute_ns)[0];
export const mQuery = Object.keys(tachyonsJS.absolute_m)[0];
export const lQuery = Object.keys(tachyonsJS.absolute_l)[0];
// export const sQuery = Object.keys(tachyonsJS.absolute_l)[0];

export const rem = (size, base = 16) => `${size / base}rem`;

export const PageHeader = styled.h1`
  ${ty`f2 lh-title tc`}
  font-family: nunito, sans-serif;
`;

export const SectionHeader = styled.h2`
  ${ty`f2 ttu tc lh-title fw8`}
  ${ty`${(p) => p.ty}`}
`;

export const SubHeader = styled.h3`
  ${ty`f4 tc pv2 lh-title`}
`;

export const BodyText = styled.p`
  ${ty`lh-copy`}
  ${ty`${(p) => (p.noCenter ? "" : "tc center")}`}
  ${ty`${(p) => {
    switch (p.fontSize) {
      case "large":
        return "f5";
      case "medium":
        return "f4";
      case "small":
        return "f7";
      default:
        return "";
    }
  }}`}
`;

export const Button = styled(
  ({ className, children, to, center, ...others }) => {
    const classes = classnames(
      className,
      "pointer dim no-inherit no-link-style"
    );
    others = others || [];
    let safe = _.pickBy(
      others,
      (v, p) =>
        !["arrowDirection", "arrowColor", "css"].includes(p) && !/_css/.test(p)
    );

    let Element = "div";
    if (to) {
      Element = StyledLink;
    }

    let el = (
      <Element to={to} className={classes} {...safe}>
        {children}
      </Element>
    );

    if (center) {
      return (
        <div
          css={`
            ${ty`flex`}
          `}
        >
          {el}
        </div>
      );
    } else {
      return el;
    }
  }
)`
  ${ty`br-pill ba pv2 ph4 dib fw7 ttu`}

  ${ty`black bg-white`}

  ${ty`${(p) => p.center && "db center mw5 tc"}`}
  ${ty`${(p) => p.ty}`}

  ${(p) => {
    if (p.whiteshadow) {
      return { boxShadow: "0px 2px 4px 0px #FFFFFF" };
    } else {
      return { boxShadow: "0px 2px 4px 0px rgba( 0, 0, 0, .50 )" };
    }
  }}
  line-height: 1.4;
  user-select: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
`;

export const ArrowButton = styled(Button)`
  ${ty`br5 pv2 fw4 lh-solid justify-between items-center inline-flex`}
  min-height: 3.125rem;
  &:after {
    ${ty`flex-shrink-0`}

    ${ty`${(p) => (p.arrowDirection === "left" ? "mr3" : "ml3")}`}
    content: " ";
    display: block;
    height: 1.75rem;
    width: 1.625rem;
    ${({ arrowColor, arrowDirection }) => {
      let bgImage;
      switch (arrowColor) {
        case "red":
          bgImage = arrowRightRed;
          break;
        case "white":
          bgImage = arrowRightWhite;
          break;
        case "black":
        default:
          bgImage = arrowRightBlack;
      }

      let transform, order;
      if (arrowDirection === "left") {
        transform = "scaleX(-1)";
        order = -1;
      }

      return {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${bgImage})`,
        transform,
        order,
      };
    }}
  }
`;

export const FlexRow = styled.div`
  ${ty`flex content-between justify-center flex-wrap`}

  /* & > * {
    ${ty`ph1`}
  } */
  ${ty`${(p) => p.ty}`}
`;

export const Row = styled(({ className, children }) => {
  const classes = classnames(className, "cf");
  return <div className={classes}>{children}</div>;
})`
  ${ty`cf ph2-ns`}
`;

export const ColHalf = styled.div`
  ${ty`fl w-100 w-50-l pa2`}
`;

export const ColThird = styled.div`
  ${ty`fl w-100 w-third-l pa2`}
`;

export const ColTwoThirds = styled.div`
  ${ty`fl w-100 w-two-thirds-l pa2`}
`;

export const ColQuarter = styled.div`
  ${ty`fl w-100 w-25-l pa2`}
`;

export const ColThreeQuarters = styled.div`
  ${ty`fl w-100 w-75-l pa2`}
`;

export const Image = styled((p) => {
  let { fluid, ...others } = p;
  // console.log(p);

  let safe = _.pickBy(
    others,
    (v, p) => !["fluid", "full"].includes(p) && !/_css/.test(p)
  );

  if (fluid) {
    let img = <Img {...safe} fluid={fluid} />;
    let { onClick } = p;
    if (onClick) {
      return <div onClick={onClick}>{img}</div>;
    } else {
      return img;
    }
  } else {
    return <img {...safe} alt="" />;
  }
})`
  ${ty`${(p) => (p.full ? "w-100" : "w-auto db center mw-100-l mw5")}`}
`;

export const PageWrapper = styled.div`
  ${ty`center ph3`}
  ${ty`${(p) => (p.full ? "ph0" : "")}`}
  ${ns(`max-width: 80rem`)}
`;

export const Section = styled(({ className, children, full }) => {
  return (
    <div className={className}>
      <PageWrapper full={full}>{children}</PageWrapper>
    </div>
  );
})`
  ${ty`${(p) => (p.full ? "w-100" : "pv5-ns pv4 w-100")}`}
`;

export const WhiteSection = styled(Section)`
  ${ty``}
`;
export const GraySection = styled(Section)`
  ${ty`bg-mid-gray white`}
`;
export const DarkGraySection = styled(Section)`
  ${ty`bg-near-black white`}
`;

export const RedSection = styled(Section)`
  ${ty`bg-red`}
  ${SectionHeader}, ${SubHeader} {
    ${ty`white`}
  }
`;

export const linkStyles = css`
  &.no-link-style {
    ${ty`no-underline`}
  }
  &:not(.no-link-style) {
    ${ty`link underline-hover`}
  }

  &:not(.no-inherit) {
    &:visited,
    &:active,
    & {
      color: inherit;
    }
  }
`;

const InternalLink = styled(Link)`
  ${linkStyles}
`;
const ExternalLink = styled.a`
  ${linkStyles}
`;

// export const StyledLink = (props) => {
//   let isExternal =
//     props && props.href && (props.href.indexOf("http") == 0 || props.href.indexOf("//") == 0);
//   //   return "";
//   return isExternal ? <ExternalLink {...props} /> : <InternalLink {...props} />;
// };

export const StyledLink = ({
  children,
  to,
  href,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  if (!to && href) {
    to = href;
  } else if (to && href) {
    throw new Error(`bad to/href ${to}/${href}`);
  }
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <InternalLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </InternalLink>
    );
  }
  return (
    <ExternalLink href={to} target="_blank" {...other}>
      {children}
    </ExternalLink>
  );
};

export const Card = styled.div`
  ${ty`pv4 ph4-l ph3 mb4 mb0-ns`}
  /* max-width: 21.8125rem; */

  ${SectionHeader} {
    ${ty`mb4`}
  }

  ${Image} {
    ${ty`mb3`}
  }
`;

export const RichText = styled.div`
  ${ty`lh-copy`}
`;
