import React from "react";
import styled from "styled-components";
import { NavItem, NavItems } from "../components/Navbar";
import instagram from "../img/social/instagram.svg";
import ty from "../styled-tachyons";
import {
  BodyText,
  Button,
  ColThird,
  ColTwoThirds,
  FlexRow,
  GraySection,
  Image,
  lQuery,
  Section,
  StyledLink,
  SubHeader,
} from "../styles";
import { encode } from "../templates/contact-page";

const FooterNavList = styled.div`
  ${ty`flex flex-column justify-start items-center content-center mt4`}
  ${ty`flex-row-l justify-between-l mt0-l`}
  padding-left: 5%;
  padding-right: 5%;
`;

const FooterStyle = styled(GraySection)`
  ${ty`bg-dark-gray`}

  ${NavItem} {
    ${ty`fw5 mb4 mb0-l`}
  }

  ${BodyText} {
    ${ty`ma0`}
  }
`;

const FooterRow = styled(FlexRow)`
  ${ty`justify-between`}
  & > * {
    ${ty`w-100 w-auto-l`}
  }
`;

const NewsLetterInput = styled.input.attrs({})`
  ${ty`input-reset bn bb ma0-l mv3`}

  &,&::placeholder {
    ${ty`tc`}
  }
  &::placeholder {
    ${ty`ttu f7`}
  }
  &:not(:last-child) {
    ${ty`mr4-l`}
  }
`;

const NewsLetterFormFields = styled(ColTwoThirds)`
  ${ty`flex-l justify-between`}
`;
const NewsLetterForm = styled(({ className }) => {
  const [thankYou, setThankYou] = React.useState(false);
  const onSubmit = React.useCallback(async (e) => {
    e.preventDefault();
    const form = e.target;
    const values = {
      name: form.elements.name.value,
      email: form.elements.email.value,
      "bot-field": form.elements["bot-field"].value,
    };
    // console.log({ values });
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...values,
        }),
      });
      setThankYou(true);
    } catch (e) {
      alert(e);
    }
  }, []);

  if (thankYou) {
    return <h3>Thanks for signing up!</h3>;
  }

  return (
    <form
      className={className}
      onSubmit={onSubmit}
      name="newsletter"
      method="post"
      // action="/contact/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="newsletter" />
      <div hidden>
        <label>
          Don’t fill this out: <input name="bot-field" id="bot-field" />
        </label>
      </div>
      <NewsLetterFormFields>
        <NewsLetterInput
          placeholder="Enter your Name"
          id="name"
          name="name"
          required
        />
        <NewsLetterInput
          placeholder="Enter your Email Address"
          type="email"
          id="email"
          name="email"
          required
        />
      </NewsLetterFormFields>
      <Button as="button" type="submit">
        Subscribe
      </Button>
    </form>
  );
})`
  ${ty`flex content-between justify-around flex-wrap`};

  ${NewsLetterInput} {
    ${ty`w-100`}
  }
`;

const SubscribeHeader = styled(SubHeader)`
  ${ty`f3`}
  font-family: nunito, sans-serif;
`;

const SocialLinks = styled.div`
  flex-shrink: 1;
  ${ty`mt5 mt0-l`}
  ${lQuery} {
    order: 999;
  }
`;
const SocialLink = styled(StyledLink)`
  ${ty`mw3 db center mt2`}
`;

const Footer = () => {
  return (
    <>
      <Section
        css={`
          ${ty`b--silver bt pv4-ns`}
        `}
      >
        <FlexRow ty="flex-wrap items-center">
          <ColThird>
            <SubscribeHeader>Subscribe to Our Newsletter!</SubscribeHeader>
          </ColThird>
          <ColTwoThirds>
            <NewsLetterForm />
          </ColTwoThirds>
        </FlexRow>
      </Section>
      <FooterStyle>
        <FooterRow>
          <div
            style={{
              flexShrink: 0,
            }}
          >
            <div
              css={`
                ${ty`washed-red tc`}
              `}
            >
              <SubHeader
                css={`
                  ${ty`f3 pb0`}
                `}
              >
                Art Reality Studio
              </SubHeader>
              <BodyText fontSize="small">Based in Los Angeles, CA</BodyText>
            </div>
          </div>
          <SocialLinks>
            <FlexRow>
              <SocialLink href="https://www.instagram.com/artrealitystudio/">
                <Image src={instagram} />
              </SocialLink>
            </FlexRow>
          </SocialLinks>
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <FooterNavList>
              <NavItems />
            </FooterNavList>
          </div>
        </FooterRow>
        <FooterRow>
          <BodyText
            fontSize="small"
            css={`
              ${ty`pt4`}
            `}
          >
            <StyledLink href="/">www.artrealitystudio.org</StyledLink> | &copy;
            2019-2021
          </BodyText>
          <BodyText
            fontSize="small"
            css={`
              ${ty`pt2 pt4-l`}
            `}
          >
            <StyledLink
              href="https://drive.google.com/file/d/1jb_GAFO9-XuUw_6CtBWTW_son6GxM65V/view"
              style={{ textDecoration: "underline" }}
            >
              Privacy Policy
            </StyledLink>{" "}
            |{" "}
            <StyledLink
              href="https://drive.google.com/file/d/16ojqHjQHeUykJ0Fv1Z4DFkJncGtPjRX0/view"
              style={{ textDecoration: "underline" }}
            >
              Terms of Use
            </StyledLink>
          </BodyText>
        </FooterRow>
      </FooterStyle>
    </>
  );
};

export default Footer;
